.lds-hourglass {
    display: flex;
    margin: auto;
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
  }
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 0.8rem solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  .lds-hourglass-big {
    display: flex;
    margin: auto;
    margin-top: 4rem;
    position: relative;
    width: 3rem;
    height: 3rem;
  }
  .lds-hourglass-big:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 1.5rem solid $black;
    border-color: $black transparent $black transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  .lds-hourglass-big-white {
    display: flex;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    position: relative;
    width: 2rem;
    height: 2rem;
  }
  .lds-hourglass-big-white:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 1rem solid white;
    border-color: white transparent white transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }




// codepen formatting


