.mob-header {
  background-color: $black;
  box-shadow: 0 0px 5px $hShadow;
  color: white;
  min-height: 52px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 150;
}

.mob-logo {
  border-radius: 12px;
  cursor: pointer;
  max-height: 12vh;
  transition: 0.2s;
}

/*Εδώ για το mobile menu*/
.mob-menu-wrapper {
  background: $black;
  box-shadow: 0 0px 5px $hShadow;
  color: white;
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  max-height: 100vh;
  overflow: scroll;
  z-index: 158;
  text-decoration: none;
}
.mob-menu a{
    text-decoration: none;
}
.mob-menu-item {
  border-radius: 10px;
  color: white;
  &:active {
    background: $red;
  }
}
.mob-menu-title {
  margin: auto 0.5rem;
}
.mob-arrow-wrapper {
  height: 0.7rem;
  transition: 0.2s;
  transform: translateX(0.35rem);
  margin-top: 0.25rem;
}

/*Animation του transition*/
.mob-dropdown-enter {
  transform: translateY(-110%);
}

.mob-dropdown-enter-active {
  transform: translateY(0);
  transition: transform 180ms;
}

.mob-dropdown-exit {
  transform: translateY(0);
}

.mob-dropdown-exit-active {
  transform: translateY(-110%);
  transition: transform 180ms;
}

.mob-header-dropdown-enter {
  transform: translateY(-110%);
}

.mob-header-dropdown-enter-active {
  transform: translateY(0);
  transition: transform 180ms;
}

.mob-header-dropdown-exit {
  transform: translateY(0);
}

.mob-header-dropdown-exit-active {
  transform: translateY(-110%);
  transition: transform 180ms;
}


.filtra-component-enter {
  transform: translateX(100%);
}

.filtra-component-enter-active {
  transform: translateX(0);
  transition: transform 180ms;
}

.filtra-component-exit {
  transform: translateX(0);
}

.filtra-component-exit-active {
  transform: translateX(100%);
  transition: transform 150ms;
}



/*Animation του transition*/
.markes-filter {
  height: 100%;
  background-color: $black;
  text-align: center;
  z-index: 255;
    ::-webkit-scrollbar{
      width: 0px;
      background: transparent
    }
  button{
    margin-top: 0.25rem;
    border-radius: $br;
  }
}

.markes-wrapper {
  height: calc(100% - 7.5rem);
  overflow: scroll;
}
.active-filter{
  background-color: $red!important;
}
.markes-button {
  transition: all 0.20s;
  font-size: 1.2rem;
  z-index: 170;
  cursor: pointer;
  display: block;
  position: fixed;
  top: 16vh;
  right: 0;
  padding: 0.6rem 0.7rem;
  background-color: $black;
  color: white;
  border-radius: $br 0 0 $br;
  border-top: 2px solid $black;
  border-left: 3px solid $black;
  border-bottom: 2px solid $black;
  box-shadow: 0 0px 4px $hShadow;
    img{
      height: 1.2rem;
      margin: 0.2rem;
      transform: translateY(-1px)
    }
}

.closing-handler {
  position: fixed;
  right: 0;
  top: 0;
  width: 45%;
  height: 100vh;
  z-index: 170;
  transition: all 150ms;
}

.invisi-wall {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  z-index: 165;
}

@media (max-width: 580px) {
  .markes-filter {
    width: 100%;
  }
  .closing-handler {
    width: 100%;
  }
}