.header {
  background-color: $black;
  box-shadow: 0 0 12px 2px $hShadow;
  color: white;
  border-radius: 0 0 10px 10px;
  
  max-height: 12rem;
  position: sticky;
  top: -10.6rem;
  z-index: 150;
}

.ts-navbar {
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: $black;
  box-shadow: 0 0px 2px 0px #ffffff59;
  max-width: 1400px;
  border-radius: 10px;
}

.ts-navbar-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.menu-item {
  display: flex;
  justify-content: center;
  text-align: center;
  color: white;
  border-radius: 11px;
  padding: 0.4rem 2rem;
  font-weight: 300;
  .zwo-title {
    margin: auto 0.1rem;
    transform: translateY(-1px);
  }
}

.menu-item:hover {
  background: $red;
  cursor: pointer;
  box-shadow: 0 0px 2px 0px #ffffff59;
}

.menu-icon {
  max-height: 1.8rem;
  margin-right: 0.2rem;
}

.pouli img {
  max-height: 1.7rem;
  margin-top: 0.1rem;
}

/*Εδώ για το μενού από πάνω*/
.dropdownmenu-wrapper {
  position: absolute;
  top: 2.2rem;
  z-index: 2;
}

.subdropdownmenu-wrapper {
  position: absolute;
  right: -100%;
  min-width: 100%;
  z-index: 2;
  transform: translateY(-2.3rem);
}

.dropdownmenu {
  background-color: $black;
  border-radius: 14px;
  box-shadow: 0 0px 12px $hShadow;
}

.subdropdownmenu {
  background-color: $black;
  border-radius: 14px;
  box-shadow: 0 0px 12px $hShadow;
  right: -2%;
  position: relative;
  display: flex;
  flex-direction: column;
  
}

.subdropdownmenu a {
  color: white;
  border-radius: 13px;
  padding: 0.8rem;
  text-decoration: none;
}

.subdropdownmenu a:hover {
  background-color: $red;
  box-shadow: 0 0px 2px 0px #ffffff59;
}

.dropdowncategory {
  padding: 0.8rem;
  border-radius: 13px;

  &:hover {
    background-color: $red;
    box-shadow: 0 0px 2px 0px #ffffff59;
  }

  &:hover .arrow-wrapper {
    right: 0.1rem;
  }
}

.arrow-wrapper {
  width: 0.8rem;
  position: absolute;
  transition: 0.1s;
  right: 0.3rem;
}

.arrow {
  height: 100%;
}
.products-container {
  display: grid;
  grid-template-columns: 18% 80%;
}
.markes-filter-desk {
  background-color: white;
  border-radius: $br;
  margin-right: 1rem;
  text-align: center;
  box-shadow: 0px 0px 20px -4px $hShadow;
  height: fit-content;
  position: sticky;
  top: 2rem;
  
  .dokimastikoGroup {
    &:hover {
      box-shadow: 0 0 12px -2px $sShadow;
    }
  }
  h3{
    font-size: 1.35rem;
    color: black;
    position: relative;
    margin: 1rem 0;
    margin-bottom: 2rem;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: -0.75rem;
      height: 1px;
      width: 70%;
      background-color: $red;
      opacity: 0.2;
    }
  }
}
@media (min-width: 768px){
  .image-gallery-icon:hover {
    color: $red;
  }
}
/*Εδώ για το μενού από πάνω*/