$red: #be1e2d;
$black: #011627;
$lightB: #01162733;
$bg: #fafff8;
$hShadow: #2f2f2f94;
$sShadow: rgba(0, 0, 0, 0.15);
$hGrey: #2f2f2f;
$br: 13px;
$grey: #747474;
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "css/desktop-menu";
@import "css/burger.scss";
@import "css/mob-menu.scss";
@import "css/admin.scss";
@import "css/loader.scss";
@font-face {
	font-family: VAG;
	src: url("./fonts/VAG-HandWritten.otf");
}

* {
	margin: 0;
	padding: 0;
}
html {
	scroll-behavior: smooth;
	height: 100%;
}
body {
	margin: 0;
	min-height: 100vh;
	background-color: $bg;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: "Ubuntu", sans-serif;
}
.bg {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: -3;
	background: url("./components/layout/bg.svg");
	opacity: 0.1;
}
.bg-kallyma {
	height: 100%;
	position: fixed;
	z-index: -2;
	background: $bg;
	opacity: 0.25;
	left: 0;
	right: 0;
}

ul {
	list-style: none;
	margin-bottom: 0;
}
input {
	text-align: center;
}

.page-container {
	min-height: 105vh;
	position: relative;
}

.content-container {
	padding-bottom: 80rem;
	padding-top: 3.5rem;
}

.header-text {
	display: none;
}

.logo {
	max-height: 10.6rem;
	cursor: pointer;
}

.footer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	background-color: $black;
	box-shadow: 0 2px 6px #2f2f2f;
	color: white;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	padding: 0.8rem;
	word-break: break-word;
	padding-bottom: 5rem;
	border-top: 1px solid $red;
	h6 {
		display: inline-block;
		padding: 0.5rem;
		font-size: 1.3rem;
	}
	.foo-logo {
		width: 25%;
		margin: auto;
		border-radius: $br;
		grid-column-end: span 2;
	}
	iframe {
		border-radius: 12px;
		width: 65%;
		height: 80%;
	}
	.social-media {
		margin: auto;
		width: 80%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		height: 100%;
		h6 {
			justify-self: flex-start;
		}
		a {
			justify-self: center;
			text-align: start;
		}
	}
}
@media (max-width: 1279px) {
	.footer {
		grid-template-columns: 1fr;
		.foo-logo {
			display: none;
		}
		.social-media {
			width: 50%;
		}
		.wrario-wrapper {
			margin-top: 2rem;
		}
	}
}
@media (max-width: 768px) {
	.footer {
		.katasthmata-wrapper {
			display: block;
		}
	}
}
@media (min-width: 1280px) {
	.footer {
		grid-template-columns: repeat(5, 1fr);
		.foo-logo {
			grid-column-end: span 5;
			margin-bottom: 4rem;
		}
		.katasthmata-wrapper {
			grid-column-end: span 3;
		}
	}
}
.footer-title-wrapper {
	min-height: 3.4rem;
	h6 {
		padding: 0.4rem;
		margin-bottom: 2.5rem;
		border-radius: $br;
		font-size: 1.3rem;
		font-weight: 700;
	}
}
.footer-katasthma {
	display: inline-block;
	padding: 0.4rem;
	border-radius: $br $br 0 0;
	font-size: 1.1rem;
	font-weight: 700;
	position: relative;
	background: $red;
}
.katasthmata-wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	.footer-title-wrapper {
		grid-column-end: span 2;
	}
}
.footer-svg {
	max-height: 1.5rem;
	max-width: 1.5rem;
}
@media (max-width: 580px) {
	.footer {
		grid-template-columns: 100%;
	}
}
.phone-call {
	text-decoration: none;
}
.erwthsh-text {
	font-family: "VAG";
	font-size: 4rem;
	text-align: center;
}

.decor-img-wrapper img {
	width: 50%;
	max-width: 100px;
}
.zwakia {
	margin-bottom: 8rem;
	margin-top: 5rem;
}
.zwaki {
	color: $black;
	height: 12rem;
	&:hover {
		cursor: pointer;
	}
	&:active .zwaki-img-wrapper,
	&:hover .zwaki-img-wrapper {
		box-shadow: 0 0 20px -4px $hShadow;
		transform: translateY(-3px);
		background: rgb(255, 255, 255);
	}
	&:hover .zwaki-title {
		color: $red;
	}
}

.zwaki-title {
	margin-top: 0.3rem;
	font-size: 1.35rem;
	font-weight: 600;
	position: relative;
	font-family: "VAG";
}

.zwaki-img-wrapper {
	display: block;
	width: 10rem;
	height: 15rem;
	max-height: 80%;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
	background-color: rgba(255, 255, 255, 0.739);
	box-shadow: 0 0 12px $sShadow;
	border-radius: 50%;
	overflow: hidden;
	transition: 0.15s ease;
}

.zwaki img {
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	max-width: 90%;
	max-height: 100%;
	transition: 0.08s ease-in-out;
}

.ad-wrapperino {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 3rem;
	width: 70%;
}
@media (max-width: 1200px) {
	.ad-wrapperino {
		width: 100%;
	}
}

/* Εδώ για το ραουτερομενού */
.menu-rect {
	display: flex;
	flex-direction: column;
	height: 12rem;
	border-radius: 12px;
	cursor: pointer;
	box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
	background-color: rgba(255, 255, 255, 0.8);
	transition: all 0.1s;
	&:active,
	&:hover {
		color: $red;
		box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);
		transform: translateY(-2px);
	}
}

.dont-break {
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

.image-wrapper-menu {
	max-height: 60%;
	margin-top: 5%;
}

.image-wrapper-menu img {
	max-height: 100%;
	max-width: 100%;
}
.card {
	border: none;
}
.card-title {
	display: -webkit-box;
	-webkit-line-clamp: 4;
	height: 6rem;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.product {
	background-color: rgb(255, 255, 255);
	text-align: center;
	font-weight: 500;
	height: 22rem;
	width: 12rem;
	margin-bottom: 2.5rem;
	border-radius: 12px;
	cursor: pointer;
	overflow: hidden;
	text-overflow: ellipsis;
	box-shadow: 0 0 12px $sShadow;
	transition: transform 0.1s, box-shadow 0.1s;
	.card-subtitle {
		color: $sShadow;
		font-weight: 300;
	}
	&:active,
	&:hover {
		color: $red;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
		transform: translateY(-2px);
		transition: transform 0.15s, box-shadow 0.15s;
		.card-subtitle {
			color: $grey;
		}
	}
}
.xarths {
	display: flex;
	a {
		display: block;
		color: $black;
		text-decoration: none;
		margin: 0.2rem;
		border-radius: 13px;
		padding: 0.5rem;
		&:hover {
			color: $red;
		}
	}
}
.xarths-wrapper {
	display: flex;
	word-break: ellipsis;
	overflow: hidden;
	a {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
.product-title h3 {
	padding: 0.5rem 1.5rem;
	border-bottom: solid 1px rgb(209, 209, 209);
}
.velaki {
	display: block;
	color: $black;
	text-decoration: none;
	margin: 0.7rem;
}
.product-expanded-wrapper {
	min-height: 75vh;
}
.product-expanded {
	// border-top: solid 5px $black;
	// border-right: solid 2px $black;
	// border-bottom: solid 5px $black;
	// border-left: solid 2px $black;
	background-color: white;
	box-shadow: 0 0 12px $sShadow;
	border-radius: 13px;
	margin: 0.5rem;
	overflow: hidden;
	color: grey;
	i {
		color: $black;
	}
	p {
		font-weight: 300;
	}
	h2 {
		color: $black;
		font-weight: 700;
	}
	.phone {
		margin: 2rem auto;
		max-width: 65%;
	}
}
@media (max-width: 768px) {
	.product-expanded {
		.phone {
			max-width: 80%;
		}
	}
}
.colors {
	display: flex;
	div {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: 300;
	}
}
.product-sizes {
	list-style: none;
	font-weight: 300;
}
.card-img-top {
	height: 12rem;
	object-fit: contain;
	background-color: white;
	padding: 0.5rem;
}
.pagination {
	display: flex;
	justify-content: center;
	grid-column: 2;
	height: 2.5rem;
	justify-self: center;
	margin-bottom: 2rem;
	div {
		padding: 0.5rem 0.8rem;
		margin: 0 0.2rem;
		width: 2.2rem;
		border-radius: $br;
		cursor: pointer;
		&:focus,
		&:hover {
			background-color: $black;
			box-shadow: 0 3px 4px $hShadow;
			color: white;
		}
	}
}
.pagination-active {
	background-color: $black;
	box-shadow: 0 3px 4px $hShadow;
	color: white;
}

.image-gallery {
	height: 18rem;
	width: auto;
	margin-bottom: 6rem;
}

.image-gallery-slide img {
	height: 18rem;
	width: auto;
	object-fit: cover;
}

.fullscreen .image-gallery-slide img {
	height: 94vh;
	max-width: 100vw;
	object-fit: contain;
	object-position: center center;
}
.fullscreen {
	.image-gallery-content {
		height: 100vh;
		width: auto;
	}
}

.image-gallery-index {
	border-radius: $br;
}
.image-gallery-thumbnail-image {
	height: 4rem;
	object-fit: cover;
}
.image-gallery-svg {
	height: 3.5rem !important;
	width: 1.75rem !important;
}
.image-gallery-fullscreen-button {
	padding: 1rem;
}

.image-gallery-thumbnail,
.image-gallery-thumbnail,
.image-gallery-thumbnail {
	overflow: hidden;
	border-radius: $br;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
	border-radius: $br;
	border: 4px solid $red;
}
// kai edw to periergo checkbox

//dokimastikh metafora se elements gia dynamikothta
.dokimastikoGroup {
	background-color: #fff;
	margin: 10px auto;
	position: relative;
	max-width: 92%;
	border-radius: $br;
	overflow: hidden;
	border-top: 1px solid white;
	border-right: 2px solid white;
	border-bottom: 1px solid white;
	border-left: 2px solid white;
}
.dokimastikoGroup-selected {
	background-color: #fff;
	margin: 10px auto;
	position: relative;
	max-width: 92%;
	border-radius: $br;
	overflow: hidden;
	transition: all 50ms ease-in-out;
	box-shadow: 0 0 8px $hShadow;
	border-top: 1px solid $black;
	border-right: 2px solid $black;
	border-bottom: 1px solid $black;
	border-left: 2px solid $black;
}
.dokimastiko-label {
	border-radius: $br;
	padding: 1rem 0.5rem;
	margin: auto;
	width: 100%;
	display: block;
	text-align: left;
	color: $grey;
	cursor: pointer;
	position: relative;
	z-index: 2;
	transition: color 50ms ease-in-out;
	overflow: hidden;
}
.dokimastiko-after {
	width: 1.5rem;
	height: 1.5rem;
	content: "";
	border: 2px solid #d1d7dc;
	background-color: #fff;
	background-image: url("data:image/svg+xml,%3Csvg width='1.5rem' height='1.5rem' viewBox='0 0 1rem 1rem' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: -0.15rem -0.05rem;
	border-radius: 50%;
	position: absolute;
	right: 1rem;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	transition: all 50ms ease-in-out;
}
.dokimastiko-input {
	width: 2rem;
	height: 2rem;
	order: 1;
	z-index: 2;
	position: absolute;
	right: 1rem;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	visibility: hidden;
}
//dokimastikh metafora se elements gia dynamikothta EZ SA TH MANA TOUS TH GGKKKKKARIOLA HTANE
.products-wrapper {
	margin-bottom: auto;
}
@media (max-width: 1279px) {
	.content-container {
		padding-top: 13vh !important;
	}
}
@media (max-width: 576px) {
	.content-container {
		padding-bottom: 100rem;
	}
}
@media (max-width: 768px) {
	.image-gallery-thumbnail.active,
	.image-gallery-thumbnail:focus,
	.image-gallery-thumbnail:hover {
		border-radius: $br;
		border: 3px solid $red;
	}
	.product {
		width: auto;
	}
}

.awssld__wrapper {
	border-radius: $br;
	box-shadow: 0 0 12px $sShadow;
}

.actual-screen {
	min-height: 100vh;
}

.wrario {
	display: grid;
	grid-template-columns: 4rem 1fr;
	gap: 0.25rem;
	width: fit-content;
	margin: auto;
	padding: 0.25rem;
	border-radius: $br;
	background: rgba(255, 255, 255, 0.33);
	border: 2px solid #4e5d6c8f;
	div {
		color: #9ea9b6;
		justify-self: center;
		padding: 0.25rem;
		background-color: $black;
		width: 100%;
		border-radius: $br;
	}
	span {
		width: 100%;
		border-radius: $br;
		color: white;
		justify-self: start;
		padding: 0.25rem;
		font-size: 1.05rem;
		background-color: $black;
	}
}

.diafhmish {
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	gap: 4rem;
	border-radius: $br;
	padding: 2rem 2rem 4rem;
}
@media (max-width: 768px) {
	.diafhmish {
		grid-template-columns: 1fr;
	}
}
.social-media {
	display: grid;
	gap: 1rem;
}
.epikoinwnia-btn {
	display: flex;
	align-items: center;
	padding: 0.75rem;
	font-size: 1.05rem;
	border-radius: $br;
	color: white;
	box-shadow: 0 0 12px $sShadow;
	cursor: pointer;
	text-decoration: none;
	justify-content: center;
	position: relative;
	img {
		position: absolute;
		left: 0.75rem;
		width: 1.5rem;
	}
	div {
		margin: 0 2.25rem;
	}
}

.fb {
	background-color: #2b4170;
	background: -moz-linear-gradient(left, #3b5998, #2b4170);
	background: -ms-linear-gradient(left, #3b5998, #2b4170);
	background: -webkit-linear-gradient(left, #3b5998, #2b4170);
}

.phone {
	background-color: $black;
	img {
		transform: scale(0.9);
	}
}

.insta {
	background: linear-gradient(
		45deg,
		#405de6,
		#5851db,
		#833ab4,
		#c13584,
		#e1306c,
		#fd1d1d
	);
}

.anakoinwsh {
	background-color: white;
	padding: 1rem;
	border-radius: $br;
	box-shadow: 0 0 12px $sShadow;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.anakoinwsh-text {
		margin-bottom: 1rem;
		font-size: 1.35rem;
		color: $red;
		margin-bottom: 0.5rem;
	}
	.epikoinwnia-btn {
		font-size: 0.95rem;
	}
	.down-arrow {
		max-width: 1.5rem;
		height: auto;
		margin: auto;
		margin-bottom: 0.5rem;
	}
}
